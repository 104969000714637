import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Navigation, Blog, Footer, Hero } from "../../components/Marketing"
import { Seo } from "../../components/Seo/Seo"

export default ({ data }) => {
  const breadcrumbs = [{ label: "Recueils de haïkus", url: "/recueils/" }]
  const illustration = getImage(data.illustration)

  return (
    <>
      <Seo
        title={`Recueils de haïkus | Exemple de haïkus`}
        description={`Que vous recherchiez des haïkus sur le thème de l'amour ou le printemps, ou des haïkus de Matsuo Basho, vous êtes au bon endroit.`}
        breadcrumbs={breadcrumbs}
        image={illustration.images.fallback.src}
      />
      <Navigation />
      <Hero
        illustration={illustration}
        theme={{ as: "h2", value: <>Recueils de haïkus</> }}
        title={{
          as: "h1",
          value: (
            <>
              Les recueils des <span className="text-green-600">haïkus</span>{" "}
              par thématique ou par auteur
            </>
          ),
        }}
        description={{
          value: (
            <>
              Des haïkus sur le thème de l'amour, des saisons comme le printemps
              et l'hiver, ou alors des haïkus publiés par de grands auteurs
              comme Matsuo Basho, c'est sur cette page du Temple du Haïku que
              tout se passe.
              <br />
              Nous sommes ravis d'apprendre que vous êtes arrivé jusque là et
              démontre votre passion pour le haïku. Nous espérons que vous
              prendrez plaisir à découvrir ou redécouvrir toutes ces inspirantes
              créations.
            </>
          ),
        }}
      />
      <Blog
        title={{
          as: "h3",
          value: (
            <>
              Les recueils des <span className="text-green-600">haïkus</span>
              <br />
              classées par thématiques ou par auteurs
            </>
          ),
        }}
        description={
          <>
            Pour le moment, nous avons deux grandes sections dans les recueils,
            il est possible que cela évolue en fonction de vos besoins (par
            exemple une page de recherche avancées où vous pourriez rechercher
            par auteurs et par thématiques en même temps).
          </>
        }
        articles={[
          {
            id: "auteurs",
            category: "Par auteurs",
            url: `/recueils/auteurs/`,
            title: "Les recueils par auteurs",
            image: data.auteurs,
            description: `Vous pouvez retrouver des haïkus produits par Matsuo Basho, Kobayashi Issa ou par exemple des français comme Paul-Louis Couchoud...`,
          },
          {
            id: "thematiques",
            category: "Par thématiques",
            url: `/recueils/thematiques/`,
            title: "Les recueils par thématiques",
            image: data.thematique,
            description: `Vous pouvez retrouver des haïkus de plusieurs thématiques comme l'amour, la guerre, les saisons, le printemps, l'été, l'hiver, l'automne...`,
          },
        ]}
      />

      <Footer />
    </>
  )
}

export const query = graphql`
  query GetDataToAnthologyIndex {
    illustration: file(relativePath: { eq: "illustrations/free-library.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          width: 1200
          quality: 80
        )
      }
    }
    auteurs: file(relativePath: { eq: "illustrations/monk.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          width: 600
          quality: 80
        )
      }
    }
    thematique: file(relativePath: { eq: "illustrations/flower.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          width: 600
          quality: 80
        )
      }
    }
  }
`
